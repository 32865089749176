.header {

  &__line {
    width: 100%;
    height: 21px;
    background: #d9d9d9;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 10px;
  }

  &__logo {
    width: 393px;
    height: auto;
    img {
      object-fit: contain;
    }
  }

  &__address {
    width: 300px;
    padding-top: 6px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 24px;
      height: 24px;
    }
  }

  &__address-place {
    margin-bottom: 20px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--main);
  }

  &__address-time--clock {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--main);
    span {
      font-weight: 600;
    }
  }

  &__contact-phone {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: var(--text-1);
    margin-bottom: 10px;
  }

  &__contact-email {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--main);
  }

  &__call-btn {
    margin-bottom: 14px;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
  }
  &__contact--mobile {
    display: none;
  }
  &__burger {
    display: none;
    width: 26px;
    height: 22px;
    cursor: pointer;
    flex-shrink: 0;
  }
  &__logo-mobile {
    display: none;
  }
  &__wrapper--mobile {
    display: none;
  }
}

.social-link {
  width: 32px;
  height: 32px;
}

.menu {
  background: var(--main);
}

.nav {

  &__list {
    display: flex;
    align-items: center;
    gap: 34px;
    justify-content: center;
  }

  &__item {
    padding: 0 12px;

  }

  &__link {
    padding: 12px 0;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    text-transform: uppercase;
    text-align: center;
    color: var(--white);
    position: relative;
    &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 11px;
    display: block;
    width: 100%;
    height: 1px;
    background: var(--white);
    opacity: 0.35;
  }

  }
}

.nav__item {
  &:hover .nav__link::after {
    display: none;
  }
  &:active .nav__link {
    transition: all 0.1s;
    transform: scale(1.04);
  }
}

.header__down {
  position: relative;
  &:hover .header__down-lists{
    display: flex;
  }
  &:hover .nav__link::after {
    display: block;
  }
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
  &:active .nav__link::after {
    display: none;
  }
  &-lists {
    padding: 0 23px;
    position: absolute;
    top: 39px;
    left: 0px;
    // display: flex;
    display: none;
    width: 913px;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    background: var(--main);
    border-radius: 0 0 6px 6px;
    z-index: 20;
    gap: 32px;

  }
  &-item {
    flex: 0 0 186px;
  }
  &-list {
    width: 100%;
    padding: 9px 0;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &-icon {
    width: 28px;
    height: 28px;
  }
  &-link {
    position: relative;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    color: var(--white);
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0px;
      display: block;
      width: 100%;
      height: 1px;
      background: var(--white);
      opacity: 0.35;
    }
    &:hover::after {
      display: none;
    }
  }
}
@media (max-width: 1360px) {
  .header__down-lists {
    width: 460px;
    gap: 0px 40px;
  }
}
@media (max-width: 680px) {
  .header__down-lists {
    top: 36px;
  }
  .nav__item {
    padding: 0 6px;

  }
}
@media (max-width: 615px) {
  .header__down-lists {
    width: 230px;
    gap: 0px;
  }
}

.menu__fixed-medium {
  display: none;
}
.menu__fixed {
  opacity: 0;
  display: none;
  &-wrapper {
    display: flex;
    align-items: center;
    gap: 105px;
    justify-content: end;
    min-height: 40px;
  }
  &-logo {
    width: 88px;
    height: 36px;
    flex-shrink: 0;
  }
  .header__nav {
    max-width: 726px;
    width: 100%;
  }
  .nav__list {
    justify-content: space-between;
    gap: 10px;
  }
  .header__call-btn {
    border-radius: 6px;
    width: 220px;
    height: 30px;
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: var(--white);
    margin-bottom: 0;
  }
  &.active {
    display: block;
    opacity: 1;
    position: fixed;
    width: 100%;
    transition: 1s ease;
    z-index: 100;
    top: 0;
    left: 0;
    min-height: 40px;
    padding-top: 2px;
  }
}
@media (max-width: 1240px) {
  .menu__fixed {
    .menu__fixed-wrapper{
      gap: 20px;
      justify-content: space-between;
    }
    .nav__link {
      font-size: 10px;
    }
    .nav__list {
      gap: 0px;
    }
  }
  .menu__fixed .header__nav {
    max-width: 510px;
    width: 100%;
  }
  .header__down-lists {
    top: 36px;
  }
}
@media (max-width: 950px) {
  .menu__fixed {
    display: none;
  }
  .menu__fixed.active {
    display: none;
  }
}

@media (max-width: 950px) {
  .menu__fixed-medium {
    opacity: 0;
    display: none;
    .menu__fixed-wrapper {
      display: flex;
      align-items: center;
      gap: 0px;
      justify-content: space-between;
      margin-left: 45px;
    }
    .menu__fixed-logo {
      width: 88px;
      height: 36px;
      flex-shrink: 0;
    }
    .header__nav {
      max-width: 485px;
      width: 100%;
    }
    .nav__list {
      justify-content: space-between;
      gap: 0px;
    }
    .nav__link {
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 10px;
      line-height: 130%;
      text-transform: uppercase;
      text-align: center;
      color: var(--white);
    }
    .header__call-btn {
      border-radius: 6px;
      width: 130px;
      height: 30px;
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      color: var(--white);
      margin-bottom: 0;
      margin-left: 10px;
    }
    .dl-menuwrapper .dl-menu {
      top: 37px;
    }
    &.active {
      display: block;
      opacity: 1;
      position: fixed;
      width: 100%;
      transition: 1s ease;
      z-index: 100;
      top: 0;
      left: 0;
    }
  }
  .header__down-lists {
    top: 37px;
  }
}
@media (max-width: 820px) {
  .menu__fixed-medium {
    .menu__fixed-logo{
      display: none;
    }
    .menu__fixed-wrapper {
      justify-content: space-between;
    }
    .nav__item {
      padding: 0 5px;
    }
    .header__call-btn {
      width: 110px;
      height: 30px;
      font-size: 12px;
    }
  }
}
@media (max-width: 615px) {
  .menu__fixed-medium{
    .menu__fixed-wrapper{
      margin-left: 16px;
    }
  }
}
@media (max-width: 600px) {
  .menu__fixed-medium{
    .header__call-btn{
      display: none;
    }
  }
}
@media (max-width: 550px) {
  .menu__fixed-medium{
    display: none;
  }
  .menu__fixed-medium.active{
    display: none;
  }
}


@media (max-width: 1312px) {
  .header__wrapper {
    gap: 12px;
  }
}
@media (max-width: 1280px) {

  .header__logo {
    display: none;
  }
  .header__logo-mobile {
    display: block;
    flex: 0 0 177px;
    height: auto;
    img {
      object-fit: contain;
    }

  }
  .header__wrapper {
    justify-content: space-between;
    padding: 10px 0;
  }
  .header__contact-phone {
    margin-bottom: 2px;
  }
}

@media (max-width: 1070px) {
  .header__contact, .header__address{
      display: none;
  }
  .header__contact--mobile {
    display: block;
    flex: 0 0 300px;
    .header__address-place {
      margin-bottom: 0;
    }
  }
}

// для 768px
@media (max-width: 950px) {

  .header__contact-phone {
    font-size: 16px;
  }
  .header__address-place  {
    font-size: 12px;
  }
  .header__contact--mobile {
    display: block;
    flex: 0 0 215px;
  }
  .social-link {
    width: 24px;
    height: 24px;
  }
  .nav__list {
    gap: 0px;

  }
  .header__burger {
    display: block;
  }
  .header__nav {
    position: relative;
    z-index: 100;
    max-width: 560px;
    width: 100%;
    margin: 0 auto;

  }
  .header__line {
    display: none;
  }
  .header__call-btn {
    margin-bottom: 7px;
  }
  .header__social {
    gap: 20px;
  }
  .header__address-place {
    margin-bottom: 0;
  }
  .header__wrapper {
    margin-bottom: 0px;
  }
}

@media (max-width: 681px) {
  .nav__link {
    font-size: 10px;
  }
  .header__logo-mobile {
    flex: 0 0 114px;
  }
  .header__call-btn {
    width: 158px;
  }
}

@media (max-width: 614px) {
  .header__nav {
    margin-left: 20px;
  }
}


@media (max-width: 550px) {
  .header__wrapper--mobile {
    display: block;
  }
  .header__wrapper--desctop {
    display: none;
  }
  .header__wrapper--mobile {
    margin-bottom: 13px;
    .header__logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: right;
      position: relative;
    }
    .header__logo {
      display: block;
      // width: 245px;
      width: 245px;
      height: 58px;
    }
    // .header__burger {
    //   display: block;
    //   width: 26px;
    //   height: 22px;
    // }
    .header__logo-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-top: 3px;
    }
    .header__btn-mobile {
      background-color: transparent;
      border: 1px solid var(--accent);
      color: var(--accent);
    }
    .header__call-btn {
      margin-bottom: 0;
    }
    .btn-main {
      width: 48%;
    }
  }
  .header__wrapper {
    padding: 0;
  }

}

@media (max-width: 320px) {
  .header__wrapper--mobile {

    .header__logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 19px;
    }
}
}

.dl-menuwrapper--mobile {
  display: none;
}
.dl-menuwrapper li > a:not(:only-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 9px;
  background: url("../img/polygon-right.svg") no-repeat center ;
}

.dl-menuwrapper li.dl-back:after {
  content: "";
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  width: 6px;
  height: 9px;
  background: url("../img/polygon-left.svg") no-repeat left center;
  right: auto;
  left: 0;
}
.dl-menuwrapper .dl-menu.dl-menuopen {
  padding: 21px 40px 26px 40px;
}
.dl-menuwrapper li a {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--white);
}
.dl-menuwrapper li a span {
border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.dl-menuwrapper li:not(:last-child) {
  margin-bottom: 14px;
}
.dl-menuwrapper {
  z-index: 10;
}
.dl-menuwrapper li.dl-back > a {
  padding-left: 19px;
}
.dl-menuwrapper li.dl-back {
  position: relative;
}
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu .dl-back a::after{
  display: none;
}

.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu .dl-back a{
  padding-bottom: 21px;
  border-bottom: 1px solid #fff;
  margin-bottom: 21px;
}

.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  border-radius: 0px;
}
.dl-menu__close {
  position: absolute;
  top: 0px;
  // right: -21px;
  right: 0;
  width: 18px;
  height: 18px;
  z-index: 100;
}
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu {
  position: relative;
}
.dl-menuwrapper--desctop .dl-menu.dl-menuopen .dl-menu__close.dl-close{
  display: none;
}
.dl-menuwrapper--desctop .dl-menu.dl-menuopen.dl-subview .dl-menu__close.dl-close{
  display: block;
  right: 20px;
  top: 20px;
}

@media (max-width: 680px) {
.dl-menuwrapper .dl-menu {
  top: 37px;
}
}

@media (max-width: 550px) {
  .dl-menuwrapper--desctop {
   display: none;
  }
  .dl-menuwrapper--mobile {
    display: block;
    left: 0;
  }

  .dl-menuwrapper button:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 50px;
    background: #4c699b;
    top: 1px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 0 10px 0 #4c699b, 0 20px 0 #4c699b;
    box-shadow: 0 10px 0 #4c699b, 0 20px 0 #4c699b;
}
.header__wrapper--mobile .header__burger {
  background: transparent;
}
.dl-menuwrapper button.dl-active{
  background: transparent;
}
.dl-menuwrapper button {
  background: transparent;
}
.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active {
  background: transparent;
}

.dl-menuwrapper button {
  width: 26px;
  height: 24px;
  top: 0px;
}
.dl-menuwrapper .dl-menu.dl-menuopen {
  border-radius: 6px;
}
.dl-menu__close {
  top: 20px;
  right: 20px;
}
.dl-menu__logo {
  width: 88px;
  height: 36px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.dl-menu.dl-menuopen.dl-subview .dl-menu__logo {
  display: none;
}

.menu {

  &__mobile-content {
    border-top: 1px solid #fff;
    margin-top: 20px;
  }

  &__mobile-btn {
    border-radius: 6px;
    width: 230px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    background: var(--accent);
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: var(--white);
    &:hover {
      background: #ff9213;
    }
    &:active {
      background: #f00;
    }
  }

  &__mobile-phone {
    .menu__mobile-img {
      width: 20px;
      height: 20px;
      a {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: var(--white);
      }
    }
  }

  &__mobile-address {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--white);
    img {
      width: 18px;
      height: 22px;
    }
  }

  &__mobile-email {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--white);
    img {
      width: 20px;
      height: 16px;
    }
  }

  &__mobile-item {
    display: flex;
    align-items: center;
    gap: 6.5px;

  }
  &__mobile-item:not(:last-child){
    margin-bottom: 10px;
  }
  &__mobile-item:first-child {
    margin-top: 20px;
  }
  &__mobile-social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin-top: 20px;
  }
  &__mobile-link {
    width: 32px;
    height: 32px;
  }
}


}

