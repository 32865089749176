html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--text-2);
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

address {
  font-style: normal;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.btn-main {
  border-radius: 6px;
  width: 220px;
  height: 52px;
  flex-shrink: 0;
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: var(--white);
  background: var(--accent);
  &:hover {
    background: #ff9213;
  }
  &:active {
    background: #f00;
  }
}

@media (max-width: 950px) {
  .btn-main {
    width: 200px;
    height: 52px;
    font-size: 16px;
  }
}
@media (max-width: 550px) {
  .btn-main {
    height: 32px;
    font-size: 14px;
    width: auto;
  }
}


.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 950px) {
  .container {
    padding: 0 20px;
  }
}
@media (max-width: 550px) {
  .container {
    max-width: 450px;
    padding: 0 10px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}
