.catalog {
  padding: 52px 0 110px;

		&__wrapper {
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      gap: 30px;
		}

		&__menu {
      flex: 0 0 247px;
		}

		&__menu-list {
		}

		&__menu-item {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      cursor: pointer;
      &:hover .catalog__menu-link{
        font-weight: 700;
        transition: all 0.5s;
        border-bottom: none;
      }
      &.active::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 58px;
        background: url("../img/Polygon-grey.svg") no-repeat center;
        width: 6px;
        height: 9px;
      }
      &:hover.active::after {
        background: url("../img/polygon.svg") no-repeat center;
      }
		}


		&__menu-link {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: var(--text-2);
      position: relative;
      border-bottom: 1px solid var(--text-2);
      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0rem;
      //   left: 0;
      //   bottom: 0px;
      //   display: block;
      //   width: 100%;
      //   height: 1px;
      //   background: var(--text-2);
      // }
    }

		&__list-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // gap: 27px 30px;
      margin-top: 40px;
      // z-index: -1;
		}

		&__list-item {
      position: relative;
      flex: 0 0 31.2%;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 2.5%;
      &:hover {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
        transition: all 0.2s;
      }
      &:hover .catalog__list-text {
        font-weight: 700;
        line-height: 100%;
        color: var(--main);
      }
      &:hover .catalog__list-text::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        display: block;
        width: 100%;
        height: 1px;
        background: var(--main);
		}
  }

		&__list-img {
      width: 100%;
      height: auto;
		}

		&__list-content {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      // min-height: 27%;
      min-height: 56px;
      padding: 0 10px;
      background: var(--white);
      opacity: 0.8;
		}

		&__list-text {
      position: relative;
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 24px;
      line-height: 87%;
      color: var(--dark-main);
		}
    &__list {
      margin-top: -12px;
    }
    &__list-breadcrumbs {
      font-family: var(--second-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      color: var(--text-2);
      a {
        position: relative;
        color: var(--main);
        padding-right: 10px;
        &:after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          content: "";
          display: inline-block;
          background: url("../img/Polygon-orange.svg") no-repeat center;
          width: 4px;
          height: 6px;
        }
      }
    }
}

.catalog {

  &__list-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
    gap: 10px;
  }

  &__list-btn {
    border: 1px solid rgba(76, 105, 155, 0.5);
    border-radius: 4px;
    width: 36px;
    height: 36px;
  }

  &__list-btn--previous {
    background: url("../img/arrow-previous.svg") no-repeat center;
  }

  &__list-btn--page {
    border: 1px solid rgba(76, 105, 155, 0.5);
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    color: var(--text-2);
    &.active {
      color: var(--main);
      border: 1px solid var(--main);
    }
  }

  &__list-btn--next {
    background: url("../img/arrow-next.svg") no-repeat center;
  }
}

.catalog__down {
  position: relative;
  &:hover .catalog__down-list {
    display: block;
  }
  &-list {
    display: none;
    position: absolute;
    width: 100%;
    top: 0;
    left: 220px;
    min-width: 220px;
    border-radius: 6px;
    background: rgba(52, 81, 130, 0.9);
    padding: 7px 11px 7px 10px;
    z-index: 10;
  }
  &-item {
    &:hover .catalog__down-link::after {
      display: none;
    }
  }
  &-item:not(:last-child) {
    margin-bottom: 14px;
  }
  &-link {
    position: relative;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--white);
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0px;
      display: block;
      width: 100%;
      height: 1px;
      background: var(--white);
      opacity: 0.35;
    }
  }
}


@media (max-width: 1320px) {
  .catalog__list-text {
    font-size: 19px;
  }
}
@media (max-width: 1110px) {
  .catalog__list-item {
    flex: 0 0 48.2%;
  }
  .catalog__list-text {
    font-size: 20px;
  }
  .catalog__list-content {
    min-height: 50px;
  }
}

@media (max-width: 950px) {
  .catalog {
    padding: 19px 0 60px;
  }
  .catalog__menu {
    display: none;
  }
  .catalog__wrapper {
    gap: 0;
    margin-left: 0px;
  }
  .catalog__list-wrapper {
    padding-top: 0px;
  }
  .catalog__list-text {
    font-size: 24px;
  }
  .catalog__list-item {
    flex: 0 0 48.5%;
    height: 205px;
    margin-bottom: 20px;
}
    .catalog__list-img {
      width: 100%;
      height: 100%;
    }
    .catalog__list-content {
      min-height: 53px;
    }
    .catalog__list-btns {
      margin-top: 0;
    }
    .catalog__list {
      margin-top: 0px;
    }
    .catalog__list-wrapper {
      margin-top: 20px;
    }
}

@media (max-width: 600px) {
  .catalog__list-item {
    height: 156px;
  }
  .catalog__list-content {
    min-height: 45px;
  }
  .catalog__list-text {
    font-size: 20px;
  }
  .form .form__item-btn {

  }
}

@media (max-width: 550px) {
  .menu {
    display: none;
  }
  .catalog {
    padding: 0px 0 40px;
  }
  .catalog__list-wrapper {
    padding-top: 0px;
    margin-top: -3px;
  }
  .catalog__list-item {
    height: 174px;
    flex: 0 0 100%;
    margin-bottom: 12px;
  }
  .catalog__list-content {
    min-height: 46px;
  }
  .catalog__list-text {
    font-size: 20px;
  }
  .catalog__list-btn {
    width: 24px;
    height: 24px;
  }
  .catalog__list-btn--page {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
  .catalog__list-btns {
    gap: 8px;
    margin-top: 8px;
  }
  .catalog__list-breadcrumbs {
    display: none;
  }
}

