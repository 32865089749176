.footer {
  background: var(--light);
  padding: 100px 0 102px;

  &__wrapper {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 57px;
  }

  &__logo {
    flex: 0 0 394px;
    height: auto;
  }

  &__content {
    // flex: 0 0 870px;
    flex-grow: 1;
  }

  &__form {
    padding-bottom: 24.5px;
    border-bottom: 2px solid #ddd;
  }

  &__form-title {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: var(--dark-main);
    margin-bottom: 12px;
  }
}

.form {

  &__wrap {
    display: flex;
    gap: 20px;
    align-items: end;
    margin-bottom: 10px;
    // flex-wrap: wrap;
  }

  &__item-label {
    display: block;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--text-2);
    max-width: 305px;
    width: 100%;
  }

  &__item-input {
    display: block;
    width: 100%;
    padding: 0 10px;
    height: 52px;
    outline:none;
    margin-top: 4px;
    border: 1px solid var(--text-2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--text-2);
    opacity: 0.4;
  }

  &__policy {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: rgba(92, 92, 101, 0.7);
    a {
      color: var(--main);
    }
  }
}

.footer {
  &__contact-wrapper {
    padding: 25.5px 0 22px;
    display: flex;
    gap: 72px;
    flex-wrap: wrap;
    border-bottom: 2px solid #ddd;
  }
  &__contact-title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: var(--text-1);
    margin-bottom: 10px;
  }
  &__information {
    display: flex;
    align-items: start;
    // justify-content: space-between;
    padding-top: 25px;
    gap: 9px;
    // flex-wrap: wrap;
  }
  &__information-content {
    max-width: 641px;
  }
  &__information-title{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--text-1);
    margin-bottom: 8px;
  }

  &__information-text {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 12px;
    line-height: 130%;
    color: var(--text-1);
  }
  &__information-develop {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--text-2);
    flex-shrink: 0;
  }
}



.footer {
  &__address {
    width: 300px;
    // padding-top: 6px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 24px;
      height: 24px;
    }
  }

  &__address-place {
    margin-bottom: 20px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--main);
  }

  &__address-time--clock {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--main);
    span {
      font-weight: 600;
    }
  }

  &__contact-phone {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: var(--text-1);
    margin-bottom: 10px;
  }

  &__contact-email {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--main);
  }

  &__call-btn {
    margin-bottom: 14px;
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 14px;
  }
}

.just-validate-error-field {
  border: 2px solid rgb(184, 17, 17);;
}
.just-validate-error-label {
  display: none;
}

@media (max-width: 1371px) {
  .footer__contact-wrapper {
    gap: 40px;
  }
}
@media (max-width: 1200px) {

}


@media (max-width: 1200px) {
  .footer__wrapper {
    display: block;
    max-width: 86%;
  }
  .footer__logo {
    margin-bottom: 20px;
  }
}
@media (max-width: 950px) {
  .form {
    .form__item-btn {
      border-radius: 6px;
      width: 220px;
      height: 52px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: var(--white);
      background: var(--accent);
    }

  }
}


@media (max-width: 950px) {
  .footer {
    background: var(--light);
    padding: 60px 0 102px;
}
  .footer__logo {
    width: 303px;
    height: auto;
    margin-bottom: 16px;
  }
  .footer__form-title {
    font-size: 20px;
  }
  .footer__address-place {
    font-size: 12px;
  }
  .footer__contact-phone {
    font-size: 16px;
  }
  .footer__address-time--clock {
    font-size: 16px;
  }
  .footer__contact-email{
    font-size: 16px;
  }
  .footer__contact-title {
    text-align: left;
    font-size: 16px;
  }
  .footer__call {
    .social-link {
      width: 48px;
      height: 48px;
    }
  }
  .footer__wrapper {
    max-width: 100%;
  }

  .footer__contact-wrapper {
    gap: 24px;
  }
  .footer__information {
    justify-content: space-between;
  }
  .form {
    .form__item-btn {
      border-radius: 6px;
      width: 220px;
      height: 52px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: var(--white);
      background: var(--accent);
    }

  }
  .footer__form {
    padding-bottom: 31.5px;
}
.footer__address {
  width: 225px;
}
.footer__contact {
  width: 225px;
}
.footer__contact-wrapper {
  flex-wrap: nowrap;
}
.footer__social {
  gap: 20px;
}
.footer__contact-wrapper {
  padding: 25.5px 0 32px;
}
.footer__information {
  padding-top: 30px;
}
}

@media (max-width: 680px) {
  .footer__contact-wrapper {
    flex-wrap: wrap;
  }
}


@media (max-width: 600px) {
  .form .form__item-btn {
    width: 158px;
    height: 52px;
    font-size: 16px;
  }
  .form__item-input {
    padding-right: 0;
  }
}

@media (max-width: 550px) {
  .footer {
    padding: 40px 0;
  }
  .footer__logo {
    margin: auto;
    margin-bottom: 1px;
    display: block;
  }
  .footer__form-title {
    font-size: 16px;
    margin-bottom: 6px;
    text-align: left;
  }
  .form__wrap {
    display: block;
  }
  .form__item-input {
    height: 42px;
  }
  .form__item-label {
    font-size: 14px;
    width: 100%;
    max-width: none;
    margin-bottom: 14px;
  }
  .form__item-input {
    padding-right: 10px;
    font-size: 12px;
  }
  .form .form__item-btn {
    width: 100%;
    max-width: none;
    height: 42px;
    margin-top: 5px;
  }
  .form__policy {
    font-size: 12px;
  }
  .footer__form {
    padding-bottom: 0px;
    margin-bottom: 0;
    border-bottom: none;
  }
  .footer__contact-wrapper {
    margin-top: 20px;
    padding-top: 0;
    gap: 0px;
  }
  .footer__contact {
    order: 1;
    width: 100%;
  }
  .footer__address {
    order: 2;
    width: 100%;
  }
  .footer__call {
    order: 3;
    width: 100%;
  }
  .footer__contact-email {
    font-size: 14px;
  }
  .footer__address-place {
    font-size: 14px;
    margin-bottom: 14px;

}
.footer__call .social-link {
  width: 32px;
  height: 32px;
}
.footer__social {
  gap: 14px;
}
.footer__call {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__contact-title {
  margin-bottom: 0;
}
.footer__address {
  border-bottom: 2px solid #ddd;
  padding-bottom: 18px;
  margin-bottom: 20px;
}
.footer__information {
  display: block;
}
.footer__information-title{
  font-size: 12px;
  margin-bottom: 6px;
}
.footer__information-text{
  font-size: 12px;
  padding-bottom: 20px;
  margin-bottom: 17px;
  border-bottom: 2px solid #ddd;
}
.footer__information-develop {
  width: 100%;
  font-size: 12px;
  text-align: center;
}
.footer__contact-wrapper {
  padding-bottom: 18px;
}
.footer__information {
  padding-top: 20px;
}
}


