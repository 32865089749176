// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Open Sans", sans-serif;
  --second-family: "Arial", sans-serif;
  --content-width: 1322px;
  --container-offset: 59px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --text-1: #131a28;
	--text-2: #5c5c65;
	--dark-main: #345182;
	--main: #4c699b;
	--accent: #fa6700;
	--light: #f7f6f6;
	--white: #fff;
}

